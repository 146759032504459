$ ->
  #Select2
  $('.select-2').select2()

  $('.select-2-teachers').select2({
    ajax: {
      url: "/users/search-teachers",
      dataType: 'json',
      delay: 250,
      data: (params) ->
        return {
          q: params.term,
          page: params.page
        }
      ,processResults: (data, params) ->
        params.page = params.page || 1;
        return {
          results: data.items,
          pagination: {
            more: (params.page * 30) < data.total_count
          }
        }
      ,cache: true
    },
    minimumInputLength: 3,
  })

  # Masks
  phoneOptions = {
    clearIfNotMatch: true,
    onKeyPress: (number, e, field, options) ->
      masks = ['(00) 0000-00009', '(00) 00000-0000']
      mask = masks[0]
      mask = masks[1] if number.replace(/\D/g, '').length == 11
      $('.phone_number').mask(mask, options);
  }

  cellphoneOptions = {
    clearIfNotMatch: true,
    onKeyPress: (number, e, field, options) ->
      masks = ['(00) 0000-00009', '(00) 00000-0000']
      mask = masks[0]
      mask = masks[1] if number.replace(/\D/g, '').length == 11
      $('.cellphone_number').mask(mask, options);
  }

  commonOptions = {
    clearIfNotMatch: true
  }

  $('.modality-wrapper').find('input').prop('disabled', true);
  $('.modality-wrapper').find('select').prop('disabled', true);
  $('.modality-wrapper').find('textarea').prop('disabled', true);
  $
  $(".modality-selected").find('input').prop('disabled', false);
  $(".modality-selected").find('select').prop('disabled', false);
  $(".modality-selected").find('textarea').prop('disabled', false);

  $('.phone_number').mask('(00) 0000-00009', phoneOptions);
  $('.cellphone_number').mask('(00) 0000-00009', cellphoneOptions);
  $('.cpf').mask("000.000.000-00", commonOptions)
  $('.zip_code').mask("00000-000", commonOptions)
  $('.date').mask("00/00/0000", commonOptions)

  $(':not(.visible)#other-gender-wrapper').hide()
  $(':not(.visible)#other-area-wrapper').hide()
  $(':not(.visible)#fonts-wrapper').hide()

  # Summernote plugin
  $('.summernote').summernote({
    height: 300,
    lang: 'pt-BR',
    fontNames: ['Proxima Nova Light','Arial', 'Arial Black', 'Comic Sans MS', 'Helvetica', 'Helvetica Neue', 'Impact', 'Courier New', 'Lucida Grande', 'Merriweather', 'Times New Roman', 'Tahoma', 'Verdana'],
    toolbar: [
      ['style', ['style','bold', 'italic', 'underline', 'clear']],
      ['fontname', ['fontname']],
      ['fontsize', ['fontsize']],
      ['color', ['color']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['height', ['height']],
      ['insert', ['link','picture','video']],
      ['text', ['undo','redo']],
      ['misc', ['fullscreen', 'codeview', 'help']],
    ]
  });

  # contact-form
  $('#contact-form').bind "ajax:success", (e, data, status, xhr) ->
    $(".form-group.has-error .help-block").remove()
    $(".has-error").toggleClass("has-error")
    $('#message').attr('placeholder', 'Mensagem enviada com sucesso')
    $('#name').val('')
    $('#email').val('')
    $('#subject').val('')
    $('#message').val('')


  $('#contact-form').bind "ajax:error", (e, xhr, status, error) ->
    $(".form-group.has-error .help-block").remove()
    $(".has-error").toggleClass("has-error")
    response = xhr.responseJSON
    $name = $('#name')
    $email = $('#email')
    $subject = $('#subject')
    $message = $('#message')

    appendError($name, response['name'] ) if response.hasOwnProperty('name')
    appendError($email, response['email'] ) if response.hasOwnProperty('email')
    appendError($subject, response['subject'] ) if response.hasOwnProperty('subject')
    appendError($message, response['message'] ) if response.hasOwnProperty('message')

  $(".state-selector").change (e) ->
    stateID = $(this).val()
    $select = $('.city-selector')
    $.ajax({
      type: "GET",
      url : "/state/#{stateID}/cities",
      success : (data) ->
        $select.empty().append("<option value=''>Selecione o município...</option>")
        $.each data.cities, (key, value) ->
          $select.append('<option value=' + value.id + '>' + value.name + '</option>')
        # console.log(data);
    },"json");

  $('.btn-find-school').click (e) ->
    e.preventDefault();
    $select = $('.school-selector')
    stateID = $('.state-selector').val()
    cityID = $('.city-selector').val()
    query = $('.school-query').val()

    $.ajax({
      type: "GET",
      url : "/school/find/#{stateID}/#{cityID}/#{query}",
      success : (data) ->
        $select.empty().append("<option value=''>Selecione a escola...</option>")
        $.each data.schools, (key, value) ->
          $select.append('<option value=' + value.id + '>' + value.name + '</option>')
        $select.focus()
    },"json");

  $('.btn-add-student').click (e) ->
    e.preventDefault()
    $table = $('#students-list')
    $studentName = $('#student_name')
    $studentBirthdate = $('#student_birthdate')
    $studentGender = $('#student_gender :selected')

    if $studentName.val() != '' && $studentGender.val() != ''
      index = $table.find('tr').length
      newRow = """<tr>
                    <td><input type='hidden' name="students[#{index}][name]" value="#{$studentName.val()}"> #{$studentName.val()}</td>
                    <td><input type='hidden' name="students[#{index}][birthdate]" value="#{$studentBirthdate.val()}"> #{$studentBirthdate.val()}</td>
                    <td><input type='hidden' name="students[#{index}][gender]" value="#{$studentGender.val()}"> #{$studentGender.text()}</td>
                    <td><a class="btn btn-danger btn-xs btn-remove-student">[-] Remover</a></td>
                  </tr> """

      $table.prepend(newRow)

      $studentName.val('')
      $studentBirthdate.val('')
      $studentGender.removeAttr("selected");
    else
      $studentName.attr('placeholder', 'Preencha o nome do aluno.')


  $(document).on 'click', '.btn-remove-student', (e) ->
    e.preventDefault()
    $(this).closest("tr").remove()

  $('.btn-add-teacherC').click (e) ->
    e.preventDefault()
    $table = $('#teachersCollaborate-list')
    $teacherCName = $('#teacherC_name')
    $teacherCBirthdate = $('#teacherC_birthdate')
    $teacherCGender = $('#teacherC_gender :selected')

    if $teacherCName.val() != '' && $teacherCBirthdate.val() != '' && $teacherCGender.val() != ''
      index = $table.find('tr').length
      newRow = """<tr>
                    <td><input type='hidden' name="teachersCollaborate[#{index}][name]" value="#{$teacherCName.val()}"> #{$teacherCName.val()}</td>
                    <td><input type='hidden' name="teachersCollaborate[#{index}][birthdate]" value="#{$teacherCBirthdate.val()}"> #{$teacherCBirthdate.val()}</td>
                    <td><input type='hidden' name="teachersCollaborate[#{index}][gender]" value="#{$teacherCGender.val()}"> #{$teacherCGender.text()}</td>
                    <td><a class="btn btn-danger btn-xs btn-remove-teacherC">[-] Remover</a></td>
                  </tr> """

      $table.prepend(newRow)

      $teacherCName.val('')
      $teacherCBirthdate.val('')
      $teacherCGender.removeAttr("selected");
    else
      $teacherCName.attr('placeholder', 'Preencha o nome do professor.')


  $(document).on 'click', '.btn-remove-teacherC', (e) ->
    e.preventDefault()
    $(this).closest("tr").remove()
  
  $(".school-selector").change (e) ->
    schoolID = $(this).val()
    $.ajax({
      type: "GET",
      url : "/school/#{schoolID}/adm_dept",
      success : (data) ->
        $("#adm_dept").val(data.school.administrative_department)
        $("#type").val(data.school.type)
    },"json");

  $(".gender-selector").change (e) ->
    genderId = $(this).val()
    if genderId == '3' || genderId == 3
      $("#other-gender-wrapper").show()
    else
      $("#other-gender-wrapper").hide()

  $(".area-selector").change (e) ->
    areaId = $(this).val()
    if areaId == '3' || areaId == 3
      $("#other-area-wrapper").show()
    else
      $("#other-area-wrapper").hide()

  $(".fiocruz-prize-selector").change (e) ->
    answer = $(this).val()
    if answer == '1' || answer == 1
      $("#fonts-wrapper").show()
    else
      $("#fonts-wrapper").hide()


  $(".modality-selector").change (e) ->
    modalityID = $(this).val()
    $('.modality-wrapper').hide()
    $('.modality-wrapper').find('input').prop('disabled', true);
    $('.modality-wrapper').find('select').prop('disabled', true);
    $('.modality-wrapper').find('textarea').prop('disabled', true);
    $("#modality-#{modalityID}-wrapper").removeClass('hidden')
    $("#modality-#{modalityID}-wrapper").show()
    $("#modality-#{modalityID}-wrapper").find('input').prop('disabled', false);
    $("#modality-#{modalityID}-wrapper").find('select').prop('disabled', false);
    $("#modality-#{modalityID}-wrapper").find('textarea').prop('disabled', false);

  $(".category-selector").change (e) ->
    categoryID = $(this).val()
    $select = $('.years-selector')

    $.ajax({
      type: "GET",
      url : "/category/#{categoryID}/years",
      success : (data) ->
        console.log(data)
        $select.empty()
        $.each data.years, (key, value) ->
          $select.append('<option value=' + value.id + '>' + value.name + '</option>')
        $select.val(null).trigger("change")
        $select.focus()
    },"json");

  $("#export-works").click (e) ->
    e.preventDefault()
    $("#_excel").val(1)
    form = $(this).parents('form:first')
    form.submit()

  $("#search").click (e) ->
    e.preventDefault()
    $("#_excel").val(0)
    form = $(this).parents('form:first')
    form.submit()

  $(".sort").click (e) ->
    e.preventDefault()
    value = null
    if ($(this).hasClass('desc'))
      $(this).toggleClass('desc')
    else if ($(this).hasClass('asc'))
      $(this).toggleClass('asc')
      $(this).toggleClass('desc')
      value = "desc"
    else 
      $(this).toggleClass('asc')
      value = "asc"

    form = $('form:first')
    field = $(this).attr('id').split("-")[1]
    $('.sort-field').val(null)
    $("#" + field).val(value)
    form.submit()


@appendError = (selector, error) ->
  selector.parent().addClass('has-error')
  selector.addClass('has-error')
  selector.after("""<span class="help-block">#{error}</span>""")
