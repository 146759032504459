$ ->
  $addSubjectButton = $('#add-subject')
  $removeSubjectButton = $('#remove-subject')
  $subjectsList = $("#subjects_list")
  $selectedSubjects = $("#subjects")

  $addSubjectButton.click (e) ->
    selected = $subjectsList.find(':selected')
    if selected? && selected.val() != ''
      $selectedSubjects.append(selected)

  $removeSubjectButton.click (e) ->
    selected = $selectedSubjects.find(':selected')
    if selected? && selected.val() != ''
      $subjectsList.append(selected)
